import { propagateError } from './propagate-error';

export const isRetryableNetworkError = (response: Response) => {
  // retry on transient network errors
  // anything else is considered to be an application-level error, not infra
  return response.status === 502 || response.status === 503 || response.status === 504;
};

export type RetryOpts<T> = {
  maxRetries?: number;
  delay?: number;
  factor?: number;
  shouldRetryOnSuccess?: (response: T) => boolean;
  timeout?: number;
};

export class RetryError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'RetryError';
  }
}

export class RetryExhaustedError extends RetryError {
  constructor(message?: string) {
    super(message);
    this.name = 'RetryExhaustedError';
  }
}

// by default, we return all successful responses
const continueOnSuccess = () => false;

const abortWithTimeout = (timeout: number) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);

  return controller.signal;
};

export const retry = <T>(
  tag: string,
  cb: (abortSignal: AbortSignal) => Promise<T>,
  opts: RetryOpts<T> = {},
) => {
  const {
    maxRetries = 5,
    delay = 100,
    factor = 2,
    shouldRetryOnSuccess = continueOnSuccess,
    timeout = 10_000,
  } = opts;
  let retryCount = 0;

  const callback = async (): Promise<T> => {
    try {
      const signal = abortWithTimeout(timeout);
      const response = await cb(signal);
      if (shouldRetryOnSuccess(response)) {
        throw new RetryError(`${tag}: retry condition met`);
      }

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const logger = console;

      if (retryCount >= maxRetries) {
        logger.error(`${tag}: retries exhausted`, { err });
        throw new RetryExhaustedError(`${tag}: retries exhausted`);
      }

      retryCount++;
      await new Promise((resolve) => setTimeout(resolve, delay * factor ** retryCount));

      const error = propagateError(err);

      logger.log(`${tag}: retrying operation`, {
        retryCount,
        err: error.message,
        stack: error.stack,
      });

      return callback();
    }
  };

  return callback();
};
